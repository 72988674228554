import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import './Home.css';

const Home = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchVideoUrl = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://xs35a33xu0.execute-api.me-south-1.amazonaws.com/ReleaseMyNiggaVideo');
      setVideoUrl(response.data.videoUrl);
    } catch (error) {
      console.error('Error fetching video URL', error);
    }
    setLoading(false);
  };

  const fetchVideoUrlbetty = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://z5ke6ijp9l.execute-api.me-south-1.amazonaws.com/obesebetty');
      setVideoUrl(response.data.videoUrl);
    } catch (error) {
      console.error('Error fetching video URL', error);
    }
    setLoading(false);
  };

  return (
    <div className="home-container">
      <motion.header 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="header"
      >
        <h1>Release My Nigga</h1>
        <p className="subtitle">A Meme Website by Jabel Net</p>
      </motion.header>

      <motion.section 
        className="meme-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        {!videoUrl ? (
          <div className="content">
            <motion.p 
              className="description"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.2 }}
            >
              Click below to reveal the meme video
            </motion.p>
            <motion.button 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="button"
              onClick={fetchVideoUrl}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Show Video'}
            </motion.button>
          </div>
        ) : (
          <motion.div 
            className="video-container"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <video src={videoUrl} controls autoPlay className="video" />
          </motion.div>
        )}
      </motion.section>

      <motion.footer 
        className="footer"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1 }}
      >
        <p>&copy; 2024 Jabel Net. All rights reserved.</p>
      </motion.footer>
    </div>
  );
};

export default Home;
