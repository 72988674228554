import React from 'react';
import './App.css';

const About = () => {
  return (
    <div className="container">
      <h1 className="title">About Release My Nigga</h1>
      <p className="subtitle">
        Welcome to Release My Nigga, a meme website developed by Jabel Net. The idea is simple: click a button, reveal a funny video! Enjoy the humor, and share the fun with friends.
      </p>
    </div>
  );
};

export default About;
