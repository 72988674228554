import React, { useState } from 'react';
import './App.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Thank you, ${name}. We will contact you soon!`);
  };

  return (
    <div className="container">
      <h1 className="title">Contact Us</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <input 
          type="text" 
          placeholder="Name" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          className="input"
        />
        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          className="input"
        />
        <textarea 
          placeholder="Your message" 
          value={message} 
          onChange={(e) => setMessage(e.target.value)} 
          className="textarea"
        />
        <button type="submit" className="button">Send</button>
      </form>
    </div>
  );
};

export default Contact;
